import React, { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Alert, Layout, Typography } from 'antd';

import Top from './Top';
import styles from './styles.module.less';
import { useBoundTrainersStore } from '@store/slices/trainers';
import { useBoundUserStore } from '@store/slices/user';

const { Content } = Layout;

const DoctorLayout = (): React.ReactElement => {
  const { doctor } = useBoundUserStore(({ doctor }) => ({
    doctor,
  }));
  const { getTrainersList } = useBoundTrainersStore(({ getTrainersList }) => ({
    getTrainersList,
  }));

  useEffect(() => {
    getTrainersList();
  }, []);

  return (
    <div>
      <Top />

      <div className={styles.container}>
        <Content>
          {!doctor?.phone && (
            <Alert
              type="warning"
              message="Предупреждение"
              style={{ marginBottom: '24px' }}
              description={
                <div>
                  <Typography.Text>
                    Необходимо добавить телефон в личной информации, чтоб клиенты видели на какой
                    номер звонить
                  </Typography.Text>{' '}
                  <br />
                  <Link to="/main/profile"> Добавить</Link>
                </div>
              }
            />
          )}

          <Outlet />
        </Content>
      </div>
    </div>
  );
};

export default DoctorLayout;
