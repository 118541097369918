import { Avatar, Button, Popconfirm, Space, TableProps, Typography } from 'antd';

import { stringToColor } from '@utils/helper';
import { ITrainer } from '@services/Trainers/interfaces';
import { IColumnsProps } from './interfaces';

const { Text } = Typography;

export const getColumns = ({ onOpenEdit, onRemove }: IColumnsProps) => {
  const columns: TableProps<ITrainer>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      rowScope: 'row',
      width: 60,
    },
    {
      title: 'Имя',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 280,
      render: (_, record) => {
        return (
          <Space>
            <Avatar
              style={{ backgroundColor: stringToColor(record.lastName), verticalAlign: 'middle' }}
            >
              {record.lastName[0]?.toLocaleUpperCase()}
            </Avatar>
            <div>
              <Text>
                {record.lastName} {record.firstName} {record.middleName}
              </Text>
            </div>
          </Space>
        );
      },
    },
    {
      title: 'Телефон',
      key: 'phone',
      dataIndex: 'phone',
    },
    {
      title: 'Доп. телефон',
      key: 'extraPhone',
      dataIndex: 'extraPhone',
    },
    {
      title: 'Действие',
      fixed: 'right',
      align: 'right',
      render: ({ id }) => (
        <Space>
          <Button onClick={onOpenEdit(id)}>Редактировать</Button>
          <Popconfirm
            title="Удалить тренера"
            description="Вы точно хотите удалить тренера?"
            okText="Да"
            cancelText="Нет"
            onConfirm={onRemove(id)}
            placement="topLeft"
          >
            <Button danger>Удалить</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return columns;
};
