import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Flex, Tag, Typography } from 'antd';
import { ArrowLeftOutlined, CopyOutlined } from '@ant-design/icons';

import { useBoundDetailPatientStore } from '@store/slices/detailsPatient';
import { stringToColor } from '@utils/helper';

import ControlButtons from './ControlButtons';
import { statusList } from '@src/interfaces/common';

const { Title, Text } = Typography;

const Info = () => {
  const navigation = useNavigate();
  const { patient } = useBoundDetailPatientStore(({ patient }) => ({
    patient,
  }));

  const {
    name,
    status,
    user: { email, hash },
    telegram,
    phone,
    note,
    firstName,
  } = patient;

  const onClick = async () => {
    if (!hash) {
      return;
    }

    const text = `https://dbtcard.com/auth/reset?hash=${hash}`;

    try {
      await navigator.clipboard.writeText(text);
      console.log('Copied to clipboard:');
    } catch (error) {
      console.error('Unable to copy to clipboard:', error);
    }
  };

  return (
    <div style={{ padding: '24px 0' }}>
      <Button
        type="link"
        onClick={() => {
          navigation('/main');
        }}
        icon={<ArrowLeftOutlined />}
        size="large"
        style={{ paddingLeft: '0' }}
      >
        Назад
      </Button>

      <Flex justify="space-between" align="start" style={{ marginTop: '24px' }} gap={24}>
        <Flex gap={24}>
          <Avatar
            style={{
              backgroundColor: stringToColor(firstName),
              verticalAlign: 'middle',
            }}
            size={48}
          >
            {name[0]?.toLocaleUpperCase()}
          </Avatar>

          <div>
            <Tag color={statusList[status.id].color} bordered={true}>
              Статус:{statusList[status.id]?.title}
            </Tag>
            <Title level={3}>{name}</Title>

            {hash && (
              <Button type="dashed" icon={<CopyOutlined />} onClick={onClick}>
                Скопировать приглашения
              </Button>
            )}

            <Flex gap={24} style={{ marginBottom: '12px', marginTop: '12px' }}>
              <Text>
                Почта: <Text type="secondary">{email}</Text>
              </Text>
              <Text>
                Телефон: <Text type="secondary">{phone || '-'}</Text>
              </Text>
              <Text>
                Telegram: <Text type="secondary">{telegram || '-'}</Text>
              </Text>
            </Flex>

            <Text>
              Заметки: <Text type="secondary">{note || '-'}</Text>
            </Text>
          </div>
        </Flex>

        <ControlButtons />
      </Flex>
    </div>
  );
};

export default Info;
