import axiosInstance from '@services/axios';
import { ISavePollPayload, TPollsListResponse } from './interfaces';

class PollsService {
  async fetchPollsList(patientId: string): Promise<TPollsListResponse> {
    const response = await axiosInstance.get<TPollsListResponse>(`/polls/${patientId}`);

    return response.data;
  }

  async updatePolls({ patientId, disabled }: ISavePollPayload): Promise<unknown> {
    const response = await axiosInstance.post<unknown>(`/polls/${patientId}`, disabled);

    return response.data;
  }
}

export const Polls = new PollsService();
