import React from 'react';
import { Button, Typography } from 'antd';
import { ReactComponent as CatIcon } from './cat.svg';
import styles from './styles.module.less';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <Typography.Title level={4} type="secondary" style={{ marginBottom: '60px' }}>
        Пошел вон, псина, видишь - сломалось
      </Typography.Title>
      <div>
        {' '}
        <CatIcon />
      </div>
      <Typography.Title level={4} type="secondary" style={{ marginBottom: '24px' }}>
        Главная страница точно работает
      </Typography.Title>
      <Button type="primary" size="large" onClick={() => navigate('/')}>
        Вернуться
      </Button>
    </div>
  );
};

export default NotFound;
