import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { v4 as uuidv4 } from 'uuid';

dayjs.extend(dayOfYear);

export const weekDays = ['Пн', 'Ср', 'Пт'];
export const WEEK_IN_MONTH = 41;

export const generateHeatMap = () => {
  const lastYear = dayjs(new Date(new Date().getFullYear(), 0, 1));
  const months = [];

  const generateEmptyList = (dayOfWeek: number) => {
    if (dayOfWeek === 1) {
      return [];
    }

    return Array.from({ length: dayOfWeek === 0 ? 6 : dayOfWeek - 1 }, () => ({
      day: 'empty',
      id: uuidv4(),
    }));
  };

  for (let i = 0; i < 12; i++) {
    const month = lastYear.add(i, 'month');
    const days = [...generateEmptyList(month.day())];

    for (let j = 0; j < month.daysInMonth(); j++) {
      const day = month.add(j, 'day').format('DD.MM.YYYY');

      days.push({ day: day, id: day });
    }

    months.push({
      title: month.format('MMM'),
      days,
    });
  }

  return months;
};
