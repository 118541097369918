import React, { useState } from 'react';
import { Button, Divider, Flex, Form, Input, Typography } from 'antd';

import { useBoundUserStore } from '@store/slices/user';
import NotFound from '@components/NotFound';
import MaskedInput from '@components/MaskedInput';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';

const DoctorInfo = () => {
  const [edit, setEdit] = useState(false);
  const { doctor: doctorInfo, updateInfoDoctor } = useBoundUserStore(
    ({ doctor, updateInfoDoctor }) => ({ doctor, updateInfoDoctor })
  );

  if (!doctorInfo) {
    return <NotFound />;
  }

  const { firstName, lastName, phone } = doctorInfo;

  const onToggle = () => {
    setEdit(!edit);
  };

  const onSubmit = (values: any) => {
    setEdit(false);
    updateInfoDoctor({ ...values, doctorId: doctorInfo.id });
  };

  return (
    <div>
      <Flex justify="space-between">
        <Typography.Title level={3}>Персональные данные</Typography.Title>

        <Button onClick={onToggle} icon={<EditOutlined />} htmlType="button">
          Редактировать
        </Button>
      </Flex>

      <Divider style={{ margin: '24px 0' }} />

      <Form
        name="basic"
        initialValues={{ firstName, lastName, phone }}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <div style={{ maxWidth: '320px' }}>
          <Form.Item name="firstName" label="Имя">
            <Input readOnly={!edit} disabled={!edit} />
          </Form.Item>

          <Form.Item name="lastName" label="Фамилия">
            <Input readOnly={!edit} disabled={!edit} />
          </Form.Item>

          <Form.Item label="Телефон" name="phone">
            <MaskedInput mask={'+{7} (000) 000-00-00'} readOnly={!edit} disabled={!edit} />
          </Form.Item>
        </div>

        <Flex justify="end">
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={!edit}>
            Сохранить
          </Button>
        </Flex>
      </Form>
    </div>
  );
};

export default DoctorInfo;
