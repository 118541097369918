import React from 'react';
import { Form, Input } from 'antd';

import { MaskedInput } from '@components/MaskedInput';
import { IProps } from './interfaces';

const defaultValues = { middleName: '', firstName: '', lastName: '', phone: '' };

const TrainerForm = ({ onSubmit, values, isEdit, form }: IProps) => {
  const initialValues = isEdit ? { ...defaultValues, ...values } : defaultValues;

  return (
    <Form
      name="basic"
      initialValues={initialValues}
      onFinish={onSubmit}
      autoComplete="off"
      form={form}
      layout="vertical"
    >
      <Form.Item
        label="Имя"
        name="firstName"
        required={true}
        rules={[
          {
            required: true,
            message: 'Please input your Имя!',
            min: 2,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Фамилия"
        name="lastName"
        required={true}
        rules={[
          {
            required: true,
            message: 'Please input your lastName!',
            min: 2,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Отчество" name="middleName" required={false}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Телефон"
        name="phone"
        required={true}
        rules={[
          {
            required: true,
            message: 'Required',
          },
        ]}
      >
        <MaskedInput mask={'+{7} (000) 000-00-00'} />
      </Form.Item>

      <Form.Item label="Доп. телефон" name="extraPhone" required={false}>
        <MaskedInput mask={'+{7} (000) 000-00-00'} />
      </Form.Item>
    </Form>
  );
};

export default TrainerForm;
