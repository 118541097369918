import { IAnswer } from '@services/Answers/interfaces';

export type TStateAnswers = {
  data: IAnswer[];
  [EAnswerDataKey.Activity]: IAnswer[];
  [EAnswerDataKey.Table]: IAnswer[];
  isLoading: boolean;
  error: string;
  range: [string, string];
};

export enum EAnswerDataKey {
  Activity = 'dataActivity',
  Table = 'dataTable',
}

export interface IPayloadAnswer {
  start?: string;
  end?: string;
}

export type TEffectsAnswers = {
  getAnswers: (params: IPayloadAnswer) => Promise<unknown>;
  onChangePeriod: (start: string, end: string) => void;
};

export type TSliceAnswers = TStateAnswers & TEffectsAnswers;
