import React, { ReactElement } from 'react';
import { theme, Tooltip, Typography } from 'antd';

import { generateHeatMap } from './utils';
import { IHeatMapDay, IProps } from './interfaces';

import styles from './styles.module.less';
import { EStatusAnswer } from '../interfaces';

const { Text } = Typography;

const HeatMap = ({ activeDates }: IProps): ReactElement => {
  const { token } = theme.useToken();
  const monthsWithDays = generateHeatMap();

  const renderItem = ({ day, id }: IHeatMapDay) => {
    const activeDay = activeDates[day];

    if (day === 'empty') {
      return <div key={id} />;
    }

    return (
      <Tooltip
        key={id}
        placement="top"
        title={<Text style={{ color: token.colorWhite, fontSize: '14px' }}>Дата: {day}</Text>}
      >
        <div
          className={styles.cell}
          style={
            activeDay
              ? {
                  backgroundColor:
                    activeDay === EStatusAnswer.Success
                      ? token.colorSuccessActive
                      : token.colorWarning,
                }
              : undefined
          }
        />
      </Tooltip>
    );
  };

  return (
    <div className={styles.heat}>
      <div className={styles.days}>
        <div className={styles.grid}>
          <Text strong style={{ fontSize: '14px', lineHeight: 1 }}>
            Пн
          </Text>
          <div />
          <Text strong style={{ fontSize: '14px', lineHeight: 1 }}>
            Ср
          </Text>
          <div />
          <Text strong style={{ fontSize: '14px', lineHeight: 1 }}>
            Пт
          </Text>
        </div>
      </div>

      {monthsWithDays.map(({ title, days }) => (
        <div key={title} className={styles.column}>
          <Text strong style={{ fontSize: '14px', textTransform: 'capitalize' }}>
            {title}
          </Text>
          <div className={styles.grid}>{days.map(renderItem)}</div>
        </div>
      ))}
    </div>
  );
};

export default HeatMap;
