import React, { useState } from 'react';
import { Button, Divider, Flex, Form, Input, Typography } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';

import { useBoundUserStore } from '@store/slices/user';

const ChangeEmail = () => {
  const [edit, setEdit] = useState(false);
  const { user } = useBoundUserStore(({ user }) => ({ user }));

  const onToggle = () => {
    setEdit(!edit);
  };

  const onSubmit = (values: any) => {
    setEdit(false);
    // updateInfoDoctor({ ...values, doctorId: doctorInfo.id });
  };

  return (
    <div>
      <Flex justify="space-between">
        <Typography.Title level={3}>Изменить e-mail</Typography.Title>

        <Button onClick={onToggle} icon={<EditOutlined />} htmlType="button">
          Редактировать
        </Button>
      </Flex>

      <Divider style={{ margin: '24px 0' }} />

      <Form
        name="basic"
        initialValues={{ email: user?.email }}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <div style={{ maxWidth: '320px' }}>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}
          >
            <Input readOnly={!edit} disabled={!edit} />
          </Form.Item>
        </div>

        <Flex justify="end">
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={!edit}>
            Изменить
          </Button>
        </Flex>
      </Form>
    </div>
  );
};

export default ChangeEmail;
