import axiosInstance from '@services/axios';
import { IFetchAnswerPayload, TAnswerListResponse } from './interfaces';

class AnswersService {
  async fetchAnswersList({
    patientId,
    end,
    start,
  }: IFetchAnswerPayload): Promise<TAnswerListResponse> {
    const params = new URLSearchParams();
    params.set('start', start);
    params.set('end', end);

    const response = await axiosInstance.get<TAnswerListResponse>(
      `/answers/${patientId}?${params.toString()}`
    );

    return response.data;
  }
}

export const Answers = new AnswersService();
