import { LoginResponseType } from '@services/User/interfaces';

export const TOKEN_KEY = '$token';

export const getStorageToken = (): Omit<LoginResponseType, 'user'> => {
  const userTokens = localStorage.getItem(TOKEN_KEY);

  if (!userTokens) {
    return { token: '', tokenExpires: 0, refreshToken: '' };
  }

  const { token, tokenExpires, refreshToken } = JSON.parse(userTokens);

  return { token, tokenExpires, refreshToken };
};

export const setStorageToken = (props: Omit<LoginResponseType, 'user'>) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(props));
};

export const removeStorageToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};
