import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import RootLayout from '@components/layouts/Root';

import { router } from './views/routes';
import './styles/index.less';

const App = () => (
  <ConfigProvider locale={ruRU}>
    <RootLayout>
      <RouterProvider router={router} />
    </RootLayout>
  </ConfigProvider>
);

export default App;
