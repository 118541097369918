import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import Api from '@services/index';

import { TSliceTrainers } from './interfaces';
import { TPayloadEditTrainer } from '@services/Trainers/interfaces';

const createTrainersSlice: StateCreator<
  TSliceTrainers,
  [['zustand/immer', TSliceTrainers], ['zustand/devtools', never]],
  [],
  TSliceTrainers
> = (set) => ({
  trainers: [],
  isLoading: false,
  error: '',
  getTrainersList: async () => {
    set({ isLoading: true });

    try {
      const { data } = await Api.trainers.fetchTrainersList();

      set({ isLoading: false, trainers: data });
    } catch (error) {
      set({ isLoading: false, error: JSON.stringify(error) });
    }
  },
  addTrainer: async (trainer) => {
    const { data } = await Api.trainers.createTrainer(trainer);

    if (!data) {
      throw new Error('Error');
    }

    set((state) => {
      state.trainers.push(data);
    });
  },
  updateTrainer: async (params: TPayloadEditTrainer) => {
    try {
      const response = await Api.trainers.updateTrainer(params);

      return set(({ trainers }) => {
        const index = trainers.findIndex((t) => t.id === params.id);

        const newElement = { ...trainers[index], ...params };
        trainers[index] = newElement;
      });
    } catch (err) {
      throw new Error(JSON.stringify(err));
    }
  },
  deleteTrainer: async (trainerId: number) => {
    const response = await Api.trainers.removeTrainer(trainerId);

    // TODO: обработать ошибку
    // if (!data?.ok) {
    //   throw new Error('Что-то пошло не так');
    // }

    return set(({ trainers }) => {
      const list = trainers.filter(({ id }) => id !== trainerId);

      return { trainers: list };
    });
  },
});

export const useBoundTrainersStore = create<TSliceTrainers>()(
  devtools(
    immer((...a) => ({
      ...createTrainersSlice(...a),
    }))
  )
);

export * from './interfaces';
