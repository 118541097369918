import React, { ReactElement, useState } from 'react';
import { Table, Typography } from 'antd';
import { useBoundTrainersStore } from '@store/slices/trainers';
import Header from './Header';
import { getColumns } from './utils';
import EditTrainer from './EditTrainer';

const { Title } = Typography;

const Trainers = (): ReactElement => {
  const [openEdit, setOpenEdit] = useState<{ open: boolean; id: number }>({
    open: false,
    id: 0,
  });
  const { trainers, isLoading, deleteTrainer } = useBoundTrainersStore(
    ({ trainers, isLoading, deleteTrainer }) => ({
      trainers,
      isLoading,
      deleteTrainer,
    })
  );

  const onRemove = (id: number) => () => {
    deleteTrainer(id);
  };

  const onOpenEdit = (id: number) => () => {
    setOpenEdit({ open: true, id });
  };

  const onCloseEdit = () => {
    setOpenEdit({ open: false, id: 0 });
  };

  const columns = getColumns({ onOpenEdit, onRemove });

  return (
    <div>
      <Title level={2}>Тренера</Title>
      <Header />
      {openEdit.open && (
        <EditTrainer trainerId={openEdit.id} isOpen={openEdit.open} onClose={onCloseEdit} />
      )}
      <Table
        columns={columns}
        dataSource={trainers}
        loading={isLoading}
        pagination={false}
        rowKey="id"
      />
    </div>
  );
};

export default Trainers;
