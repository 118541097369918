import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getStorageToken } from '@utils/storageUser';

import styles from './styles.module.less';

const AuthLayout = () => {
  const { token } = getStorageToken();

  if (token) {
    return <Navigate to="/main" replace />;
  }

  return (
    <div className={styles.auth}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
