import axiosInstance from '@services/axios';
import {
  TPatientsResponse,
  TPatientResponse,
  TStatisticsResponse,
  ICreatePatient,
  TActivityResponse,
  ISendPayload,
  IUpdateNotePaylod,
  IAddTrainer,
  IUpdatePatient,
} from './interfaces';

class PatientsService {
  async fetchPatients(): Promise<TPatientsResponse> {
    const response = await axiosInstance.get<TPatientsResponse>('/patients');

    return response.data;
  }

  async createPatient(params: ICreatePatient): Promise<TPatientResponse> {
    const response = await axiosInstance.post<TPatientResponse>('/patients', params);

    return response.data;
  }

  async updatePatient(params: Partial<IUpdatePatient>) {
    const response = await axiosInstance.patch<TPatientResponse>(`/patients/${params.id}`, params);

    return response.data;
  }

  async removePatient(id: string): Promise<any> {
    const response = await axiosInstance.delete<TPatientResponse>(`/patients/${id}`);

    return response.data;
  }

  async fetchDetailPatient(id: string): Promise<TPatientResponse> {
    const response = await axiosInstance.get<TPatientResponse>(`/patients/${id}`);

    return response.data;
  }

  async fetchActivities(userId: number): Promise<TActivityResponse> {
    const response = await axiosInstance.get<TActivityResponse>(`/activities/${userId}`);

    return response.data;
  }

  async fetchStatistics(id: string): Promise<TStatisticsResponse> {
    const response = await axiosInstance.get<TStatisticsResponse>(`/patients/${id}/statistics`);

    return response.data;
  }

  async sendPollsByPatient({ patientId, polls }: ISendPayload): Promise<unknown> {
    const response = await axiosInstance.post<{ ok: boolean }>(`/patients/${patientId}/send`, {
      patientId,
      polls,
    });

    return response.data;
  }

  async pausePatient(patientId: string): Promise<unknown> {
    const response = await axiosInstance.put<{ ok: boolean }>(`/patients/${patientId}`);

    return response.data;
  }

  async updateNote({ patientId, note }: IUpdateNotePaylod): Promise<unknown> {
    const response = await axiosInstance.put<{ ok: boolean }>(`/doctor-notes/${patientId}`, {
      note,
    });

    return response.data;
  }

  async addTrainerByPatient({ patientId, ids }: IAddTrainer) {
    const response = await axiosInstance.post<{ ok: boolean }>(
      `/patients/${patientId}/add-trainer`,
      {
        ids,
      }
    );

    return response.data;
  }
}

export const Patients = new PatientsService();
