import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Grid, Menu, Row, theme } from 'antd';
import {
  ArrowLeftOutlined,
  MailOutlined,
  SecurityScanOutlined,
  UserOutlined,
} from '@ant-design/icons';

import DoctorInfo from './DoctorInfo';
import ChangePassword from './ChangePassword';

import styles from './styles.module.less';
import classNames from 'classnames';
import ChangeEmail from './ChangeEmail';

const { useBreakpoint } = Grid;

const Profile = () => {
  const { md } = useBreakpoint();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [tabId, setTabId] = useState('doctorInfo');

  const items = [
    {
      key: 'doctorInfo',
      label: 'Персональные данные',
      icon: <UserOutlined />,
    },
    {
      key: 'changeEmail',
      label: 'Изменить e-mail',
      icon: <MailOutlined />,
    },
    {
      key: 'changePassword',
      label: 'Изменить пароль',
      icon: <SecurityScanOutlined />,
    },
  ];

  return (
    <Row gutter={24}>
      <Col xs={24} lg={8}>
        <div className={classNames(styles.menu, { _mobile: !md })}>
          <Menu
            mode={md ? 'inline' : 'horizontal'}
            activeKey={tabId}
            defaultSelectedKeys={['doctorInfo']}
            defaultOpenKeys={['sub1']}
            style={{
              padding: '12px 8px',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              marginBottom: '12px',
            }}
            items={items}
            onClick={({ key }) => {
              setTabId(key);
            }}
          />
          <Button type="link" icon={<ArrowLeftOutlined />} block={md} size="large">
            <Link to="/">Вернутся назад</Link>
          </Button>
        </div>
      </Col>

      <Col xs={24} lg={16}>
        <div
          style={{ padding: '24px', background: colorBgContainer, borderRadius: borderRadiusLG }}
        >
          {tabId === 'doctorInfo' && <DoctorInfo />}
          {tabId === 'changeEmail' && <ChangeEmail />}
          {tabId === 'changePassword' && <ChangePassword />}
        </div>
      </Col>
    </Row>
  );
};

export default Profile;
