import { Avatar, Button, Dropdown, Space, TableProps, Tag, Typography } from 'antd';
import dayjs from 'dayjs';

import { IPatient } from '@services/Patients/interfaces';
import { StatusEnum, statusList } from '@src/interfaces/common';
import { stringToColor } from '@utils/helper';
import { IGetColumns } from './interfaces';
import { MenuProps } from 'antd/lib';
import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const getColumns = ({ onOpenEdit }: IGetColumns) => {
  const columns: TableProps<IPatient>['columns'] = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      width: 280,
      render: (name: string, { id, user: { email } }) => {
        return (
          <Space>
            <Avatar style={{ backgroundColor: stringToColor(name), verticalAlign: 'middle' }}>
              {name[0]?.toLocaleUpperCase()}
            </Avatar>
            <div>
              <Text>{name}</Text>
              <br />
              <Text type="secondary">{email}</Text>
            </div>
          </Space>
        );
      },
    },
    {
      title: 'Дата отправки',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => {
        if (typeof text === 'string') {
          return dayjs(text).format('DD.MM.YYYY');
        }

        return null;
      },
    },
    {
      title: 'Статус',
      key: 'user',
      dataIndex: 'user',
      render: ({ status }) => {
        const defaultStatus = statusList[StatusEnum.default];

        if (!status) {
          return <Tag color={defaultStatus.color}>{defaultStatus.title}</Tag>;
        }

        const { id: statusId } = status as { id: StatusEnum };
        const { title, color } = statusList[statusId];

        return <Tag color={color}>{title}</Tag>;
      },
    },
    {
      title: 'Примечание',
      key: 'note',
      dataIndex: 'note',
      ellipsis: true,
    },
    {
      title: 'Действие',
      fixed: 'right',
      align: 'right',
      render: (_, record) => {
        const menuItems: MenuProps['items'] = [
          {
            label: 'Редактировать',
            key: 'edit',
            icon: <EditOutlined />,
            onClick: ({ domEvent }) => {
              domEvent.stopPropagation();
              onOpenEdit(record);
            },
          },
          {
            label: 'Удалить',
            key: 'remove',
            icon: <DeleteOutlined />,
            danger: true,
            onClick: ({ domEvent }) => {
              domEvent.stopPropagation();
              onOpenEdit(record);
            },
          },
        ];

        return (
          <Dropdown menu={{ items: menuItems }} placement="bottomRight">
            <Button type="text">
              <EllipsisOutlined style={{ fontSize: '18px' }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return columns;
};
