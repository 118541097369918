import React, { ReactElement, useEffect, useMemo } from 'react';
import { Typography } from 'antd';

import { useBoundPatientsStore } from '@store/slices';
import { ELoadingStatus } from '@src/interfaces/common';

import DataTable from './DataTable';

const { Title } = Typography;

const Patients = (): ReactElement => {
  const { fetchPatients, data, loadingStatus } = useBoundPatientsStore(
    ({ fetchPatients, data, loadingStatus }) => ({
      fetchPatients,
      data,
      loadingStatus,
    })
  );

  useEffect(() => {
    fetchPatients();
  }, []);

  const isLoading = useMemo(() => {
    if (data.length > 0) {
      return false;
    }

    return loadingStatus === ELoadingStatus.Pending;
  }, [data, loadingStatus]);

  return (
    <div>
      <Title level={2}>Пациенты</Title>
      <DataTable data={data} isLoading={isLoading} />
    </div>
  );
};

export default Patients;
