import { Answers } from './Answers';
import { Doctors } from './Doctors';
import { Patients } from './Patients';
import { Polls } from './Polls';
import { Trainers } from './Trainers';
import { User } from './User';

class Api {
  patients: typeof Patients = Patients;
  polls: typeof Polls = Polls;
  user: typeof User = User;
  answers: typeof Answers = Answers;
  trainers: typeof Trainers = Trainers;
  doctors: typeof Doctors = Doctors;
}

export default new Api();
