import React from 'react';
import AddTrainer from './AddTrainer';
import { useBoundDetailPatientStore } from '@store/slices/detailsPatient';
import List from './List';
import { Flex } from 'antd';

const Trainers = () => {
  const { patient, addTrainerByPatient } = useBoundDetailPatientStore(
    ({ patient, addTrainerByPatient }) => ({
      patient,
      addTrainerByPatient,
    })
  );

  const { trainers, id: patientId } = patient;

  const onAddTrainers = (trainersIds: number[]) => {
    addTrainerByPatient({ patientId, ids: trainersIds });
  };

  const onRemove = (id: number) => {
    const ids = trainers.filter((el) => el !== id);
    addTrainerByPatient({ patientId, ids });
  };

  return (
    <Flex vertical={true} gap={24} style={{ paddingBottom: '32px' }}>
      <AddTrainer onAddTrainers={onAddTrainers} trainersIds={trainers} />
      <List trainersIds={trainers} onRemove={onRemove} />
    </Flex>
  );
};

export default Trainers;
