import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import Api from '@services/index';

import { TSlicePolls } from './interfaces';
import {
  IDoctor,
  ILoginRequest,
  IPaylodChangePassword,
  IRegisterRequest,
} from '@services/User/interfaces';
import { removeStorageToken, setStorageToken } from '@utils/storageUser';
import { isAxiosError } from 'axios';
import { router } from '@src/views/routes';
import { IPayloadUpdateDoctor } from '@services/Doctors/interfaces';

const createUserSlice: StateCreator<
  TSlicePolls,
  [['zustand/immer', TSlicePolls], ['zustand/devtools', never]],
  [],
  TSlicePolls
> = (set) => ({
  user: null,
  doctor: null,
  isLoading: false,
  error: '',
  updateInfoDoctor: async (params: IPayloadUpdateDoctor) => {
    try {
      const { data: doctor } = await Api.doctors.updateInfoDoctor(params);

      set({ doctor });
    } catch (err: unknown) {
      if (isAxiosError<{ errors: Record<string, string> }>(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors;
      }
    }
  },
  changePassword: async (params: IPaylodChangePassword) => {
    try {
      const { data: user } = await Api.user.changePassword(params);

      if (!user) {
        throw new Error('userError');
      }
    } catch (err: unknown) {
      if (isAxiosError<{ errors: Record<string, string> }>(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors;
      }
    }
  },
  getUserMe: async () => {
    set({ isLoading: true });

    try {
      const { data: user } = await Api.user.fetchUserMe();
      const { data: doctor } = await Api.doctors.fetchInfoDoctor();

      if (!user) {
        throw new Error('User notFound');
      }

      // @ts-ignore
      set({ isLoading: false, user: user, doctor });
      // setStorageToken(rest);
    } catch (err: unknown) {
      if (isAxiosError<{ errors: Record<string, string> }>(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors;
      }
    }

    return undefined;
  },
  register: async (params: IRegisterRequest) => {
    try {
      const { data } = await Api.user.register(params);

      if (!data) {
        throw new Error('notFound');
      }
    } catch (err: unknown) {
      if (isAxiosError<{ errors: Record<string, string> }>(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors;
      }
    }

    return undefined;
  },
  login: async (params: ILoginRequest) => {
    set({ isLoading: true });

    try {
      const { data } = await Api.user.login(params);

      if (!data) {
        throw new Error('notFound');
      }

      const { user, ...rest } = data;

      set({ isLoading: false, user });
      setStorageToken(rest);
    } catch (err: unknown) {
      if (isAxiosError<{ errors: Record<string, string> }>(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors;
      }
    }

    return undefined;
  },
  logOut: () => {
    set({ user: null, isLoading: false, error: '' });
    removeStorageToken();
    router.navigate('/auth/login');
  },
});

export const useBoundUserStore = create<TSlicePolls>()(
  devtools(
    immer((...a) => ({
      ...createUserSlice(...a),
    }))
  )
);

export * from './interfaces';
