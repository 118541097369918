import React, { useMemo, useState } from 'react';
import { Button, Drawer, Form, Space, message } from 'antd';

import { useBoundTrainersStore } from '@store/slices/trainers';
import { IPayloadTrainer } from '@services/Trainers/interfaces';
import TrainerForm from '../Form';
import { IFields, IProps } from './interfaces';

const EditTrainer = ({ onClose, isOpen, trainerId }: IProps) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);
  const { updateTrainer, trainers } = useBoundTrainersStore(({ updateTrainer, trainers }) => ({
    updateTrainer,
    trainers,
  }));

  const onSubmit = (values: IPayloadTrainer) => {
    setLoading(true);

    updateTrainer({ ...values, id: trainerId })
      .then(async () => {
        onClose?.();
        form.resetFields();
      })
      .catch((err: Error | Record<string, string>) => {
        console.log('====error', err);
        if (typeof err === 'object' && err !== null) {
          const fields = Object.keys(err).reduce<IFields[]>((acc, key) => {
            // @ts-ignore
            const val = err[key];
            acc.push({ name: key, errors: [val] });

            return acc;
          }, []);

          form.setFields(fields);
        }

        setLoading(false);

        messageApi.open({
          type: 'error',
          content: 'This is an error message',
        });
      });
  };

  const trainer = useMemo(() => {
    const item = trainers.find(({ id }) => id === trainerId);

    if (!item) {
      return {};
    }

    return item;
  }, [trainerId]);

  return (
    <>
      {contextHolder}
      <Drawer
        placement="right"
        onClose={onClose}
        title="Редактировать"
        open={isOpen}
        width={480}
        extra={
          <Space>
            <Button onClick={onClose} loading={isLoading}>
              Отмена
            </Button>
            <Button onClick={() => form.submit()} type="primary" loading={isLoading}>
              Редактировать
            </Button>
          </Space>
        }
      >
        <TrainerForm form={form} onSubmit={onSubmit} isEdit={true} values={trainer} />
      </Drawer>
    </>
  );
};

export default EditTrainer;
