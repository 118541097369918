import axios, { AxiosInstance } from 'axios';

import axiosInstance, { instanceOptions } from '@services/axios';
import { getStorageToken, removeStorageToken, setStorageToken } from '@utils/storageUser';
import {
  ILoginRequest,
  LoginResponseType,
  TUserResponse,
  IRegisterRequest,
  IPaylodChangePassword,
} from './interfaces';
import { router } from '@src/views/routes';
import { IBaseResponse } from '@services/axios/interfaces';

class UserService {
  authInstance: AxiosInstance;

  constructor() {
    this.authInstance = axios.create(instanceOptions);
  }

  async changePassword(params: IPaylodChangePassword): Promise<TUserResponse> {
    const response = await axiosInstance.patch<TUserResponse>('/auth/me', params);

    if (!response.data.data) {
      throw new Error('notFound');
    }

    return response.data;
  }

  async login(params: ILoginRequest): Promise<TUserResponse> {
    const response = await this.authInstance.post<TUserResponse>('/auth/admin/email/login', params);

    if (!response.data.data) {
      throw new Error('notFound');
    }

    return response.data;
  }

  async register(params: IRegisterRequest) {
    const response = await this.authInstance.post<IBaseResponse<{ ok: boolean }>>(
      '/auth/admin/email/register',
      params
    );

    if (!response.data.data) {
      throw new Error('notFound');
    }

    return response.data;
  }

  async fetchUserMe() {
    const response = await axiosInstance.get<TUserResponse>('/auth/me');

    if (!response.data.data) {
      throw new Error('notFound');
    }

    return response.data;
  }

  async refreshToken(): Promise<string> {
    let token;

    try {
      const { refreshToken } = getStorageToken();

      const options = {
        ...instanceOptions,
        headers: {
          ...instanceOptions.headers,
          Authorization: `Bearer ${refreshToken}`,
        },
      };

      const api = axios.create(options);
      const response = await api.post<Omit<LoginResponseType, 'user'>>('/auth/refresh');

      if (!response.data) {
        throw new Error('userInvalid');
      }

      setStorageToken(response.data);
      token = response.data.token;
    } catch (err) {
      removeStorageToken();
      router.navigate('/auth/login', { replace: true });
      console.error(err);
      throw err;
    }

    return token;
  }
}

export const User = new UserService();
