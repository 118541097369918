import { Navigate, createBrowserRouter } from 'react-router-dom';

import DoctorLayout from '@components/layouts/Doctor';
import NotFound from '@components/NotFound';
import AuthLayout from '@components/layouts/Auth';

import Patients from './Patients';
import DetailsPatient from './DetailsPatient';
import SignIn from './Auth/SignIn';
import SignUp from './Auth/SignUp';
import Trainers from './Trainers';
import Profile from './Profile';

export const router = createBrowserRouter([
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        path: 'login',
        element: <SignIn />,
      },
      {
        path: 'register',
        element: <SignUp />,
      },
    ],
  },
  {
    path: 'main',
    element: <DoctorLayout />,
    children: [
      {
        path: '',
        index: true,
        element: <Navigate to="/main/patients" />,
      },
      { path: 'profile', element: <Profile /> },
      {
        path: 'patients',
        children: [
          {
            index: true,
            element: <Patients />,
          },
          {
            path: ':patientId',
            element: <DetailsPatient />,
          },
        ],
      },
      {
        path: 'trainers',
        element: <Trainers />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: '/',
    element: <Navigate to="/main/patients" replace />,
  },
]);
