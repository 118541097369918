import axiosInstance from '@services/axios';
import {
  ITrainer,
  TTrainersListResponse,
  IPayloadTrainer,
  TPayloadEditTrainer,
} from './interfaces';
import { IBaseResponse } from '@services/axios/interfaces';

class TrainersService {
  async fetchTrainersList(): Promise<TTrainersListResponse> {
    const response = await axiosInstance.get<TTrainersListResponse>('/trainers');

    return response.data;
  }

  async createTrainer(params: IPayloadTrainer): Promise<IBaseResponse<ITrainer>> {
    const response = await axiosInstance.post<IBaseResponse<ITrainer>>('/trainers', params);

    return response.data;
  }

  async updateTrainer(params: TPayloadEditTrainer): Promise<IBaseResponse<ITrainer>> {
    const response = await axiosInstance.put<IBaseResponse<ITrainer>>(
      `/trainers/${params.id}`,
      params
    );

    return response.data;
  }

  async removeTrainer(id: number): Promise<IBaseResponse<{ ok: boolean }>> {
    const response = await axiosInstance.delete<IBaseResponse<{ ok: boolean }>>(`/trainers/${id}`);

    return response.data;
  }
}

export const Trainers = new TrainersService();
