export interface IErrorResponse {
  code: string;
  displayMessage: string;
  id: string;
  message: string;
  systemId: string;
}

export interface IBaseResponse<T> {
  actualTimestamp: number;
  data: T;
  status: string;
  error?: IErrorResponse;
}

export enum ETypeInput {
  Slider = 'slider',
  Switch = 'switch',
  TextArea = 'textArea',
  Radio = 'radio',
}

export enum ELoadingStatus {
  Ok = 'ok',
  Pending = 'pending',
  Fail = 'fail',
}

export enum StatusEnum {
  'default' = 0,
  'active' = 1,
  'inactive' = 2,
  'invated' = 3,
  'draft' = 4,
}

export const statusList: Record<
  StatusEnum,
  { title: string; color: 'success' | 'error' | 'processing' | 'warning' | 'default' }
> = {
  [StatusEnum.active]: {
    title: 'Активный',
    color: 'success',
  },
  [StatusEnum.inactive]: {
    title: 'Неактивный',
    color: 'error',
  },
  [StatusEnum.invated]: {
    title: 'Приглашенный',
    color: 'processing',
  },
  [StatusEnum.draft]: {
    title: 'Черновик',
    color: 'warning',
  },
  [StatusEnum.default]: {
    title: 'Неизвестно',
    color: 'default',
  },
};

type TYear = `${number}${number}${number}${number}`;
type TMonth = `${number}${number}`;
type TDay = `${number}${number}`;
type THours = `${number}${number}`;
type TMinutes = `${number}${number}`;
type TSeconds = `${number}${number}`;
type TMilliseconds = `${number}${number}${number}`;

/**
 * Represent a string like `2021-01-08`
 */
export type TDateISODate = `${TYear}-${TMonth}-${TDay}`;

/**
 * Represent a string like `14:42:34.678`
 */
export type TDateISOTime = `${THours}:${TMinutes}:${TSeconds}.${TMilliseconds}`;

/**
 * Represent a string like `2021-01-08T14:42:34.678Z` (format: ISO 8601).
 *
 * It is not possible to type more precisely (list every possible values for months, hours etc) as
 * it would result in a warning from TypeScript:
 *   "Expression produces a union type that is too complex to represent. ts(2590)
 */
export type TDateISO = `${TDateISODate}T${TDateISOTime}Z`;
