import React, { useState } from 'react';
import { SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Flex, Input } from 'antd';
import AddTrainer from './AddTrainer';

const Header = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };

  const handleHideDrawer = () => {
    setShowDrawer(false);
  };

  return (
    <>
      <AddTrainer onClose={handleHideDrawer} isOpen={showDrawer} />
      <Flex justify="space-between" align="center" style={{ marginBottom: '24px' }}>
        <div style={{ maxWidth: '320px', width: '100%' }}>
          <Input
            addonBefore={<SearchOutlined />}
            allowClear
            placeholder="large size"
            disabled={true}
          />
        </div>

        <Button onClick={handleShowDrawer} type="primary" icon={<UserAddOutlined />}>
          Добавить
        </Button>
      </Flex>
    </>
  );
};

export default Header;
