import React, { useMemo, useState } from 'react';
import { Button, Select, Flex, Typography } from 'antd';
import { useBoundTrainersStore } from '@store/slices/trainers';
import { SelectProps } from 'antd/lib';
import { IProps } from './interfaces';

const AddTrainer = ({ onAddTrainers, trainersIds }: IProps) => {
  const [selected, setSelected] = useState<number[]>([]);
  const { trainers } = useBoundTrainersStore(({ trainers }) => ({
    trainers,
  }));

  const options: SelectProps['options'] = useMemo(
    () =>
      trainers.map(({ id, firstName, lastName, middleName, phone }) => ({
        label: `${firstName} ${lastName} ${middleName}`,
        value: id,
        desc: phone,
        disabled: trainersIds.includes(id),
      })),
    [trainers, trainersIds]
  );

  const handleChange = (value: number[]) => {
    setSelected(value);
  };

  const onSubmit = (): void => {
    const ids = [...trainersIds, ...selected];
    onAddTrainers(ids);
    setSelected([]);
  };

  return (
    <Flex gap={12}>
      <Select
        mode="multiple"
        style={{ width: '100%', maxWidth: '420px' }}
        placeholder="Добавить тренера"
        onChange={handleChange}
        options={options}
        value={selected}
        optionRender={(option) => (
          <div>
            <Typography.Text style={{ fontSize: '18px' }}>{option.label}</Typography.Text>
            <br />
            <Typography.Text type="secondary">{option.data.desc}</Typography.Text>
          </div>
        )}
      />
      <Button type="primary" onClick={onSubmit}>
        Добавить
      </Button>
    </Flex>
  );
};

export default AddTrainer;
