import React, { useMemo } from 'react';
import { Avatar, List as AntdList, Typography, Button } from 'antd';
import { useBoundTrainersStore } from '@store/slices/trainers';
import { IProps } from './interfaces';
import { stringToColor } from '@utils/helper';

const List = ({ trainersIds, onRemove }: IProps) => {
  const { trainers } = useBoundTrainersStore(({ trainers }) => ({
    trainers,
  }));

  const dataList = useMemo(() => {
    return trainers.filter(({ id }) => trainersIds.includes(id));
  }, [trainers, trainersIds]);

  return (
    <AntdList
      itemLayout="horizontal"
      dataSource={dataList}
      renderItem={(item) => (
        <AntdList.Item
          actions={[
            <Button
              type="primary"
              danger
              key="list-loadmore-edit"
              onClick={() => {
                onRemove(item.id);
              }}
            >
              Удалить
            </Button>,
          ]}
        >
          <AntdList.Item.Meta
            avatar={
              <Avatar
                style={{ backgroundColor: stringToColor(item.firstName), verticalAlign: 'middle' }}
              >
                {item.firstName[0]?.toLocaleUpperCase()}
              </Avatar>
            }
            title={
              <Typography.Text strong>
                ФИО: {item.lastName} {item.firstName} {item.middleName}
              </Typography.Text>
            }
            description={
              <div>
                <Typography.Text>Телефон: {item.phone}</Typography.Text>
                <br />
                <Typography.Text>Доп. телефон: {item.extraPhone}</Typography.Text>
              </div>
            }
          />
        </AntdList.Item>
      )}
    />
  );
};

export default List;
