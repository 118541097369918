import React, { useState } from 'react';
import { Button, Divider, Flex, Form, Input, Typography, message } from 'antd';
import { useBoundUserStore } from '@store/slices/user';
import { IChangePasswordParams } from './interfaces';

const ChangePassword = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const { changePassword } = useBoundUserStore(({ changePassword }) => ({ changePassword }));
  const [form] = Form.useForm<IChangePasswordParams>();

  const onSubmit = (values: IChangePasswordParams) => {
    setLoading(true);

    changePassword(values)
      .then(() => {
        setLoading(false);
        form.resetFields();
        messageApi.open({
          type: 'success',
          content: 'Пароль изменее',
        });
      })
      .catch(() => {
        setLoading(false);
        form.setFields([{ name: 'oldPassword', errors: ['Ошибка, неверный пароль'] }]);
      });
  };

  return (
    <div>
      {contextHolder}
      <Flex justify="space-between">
        <Typography.Title level={3}>Персональные данные</Typography.Title>
      </Flex>

      <Divider style={{ margin: '24px 0' }} />

      <Form
        name="basic"
        initialValues={{ password: '', oldPassword: '', confirmPassword: '' }}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <div style={{ maxWidth: '320px' }}>
          <Form.Item
            label="Текущий пароль"
            name="oldPassword"
            required={false}
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Новый пароль"
            name="password"
            required={false}
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Подтвердить пароль"
            name="confirmPassword"
            required={false}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error('The new password that you entered do not match!')
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </div>

        <Flex justify="end">
          <Button type="primary" htmlType="submit" loading={loading}>
            Изменить
          </Button>
        </Flex>
      </Form>
    </div>
  );
};

export default ChangePassword;
