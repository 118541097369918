import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import dayjs from 'dayjs';

import Api from '@services/index';

import { EAnswerDataKey, IPayloadAnswer, TSliceAnswers } from './interfaces';
import { useBoundDetailPatientStore } from '../detailsPatient';

const createAnswersSlice: StateCreator<
  TSliceAnswers,
  [['zustand/immer', TSliceAnswers], ['zustand/devtools', never]],
  [],
  TSliceAnswers
> = (set) => ({
  data: [],
  [EAnswerDataKey.Activity]: [],
  [EAnswerDataKey.Table]: [],
  isLoading: false,
  error: '',
  range: [dayjs().subtract(30, 'days').toISOString(), dayjs().toISOString()],
  getAnswers: async (params: IPayloadAnswer) => {
    set({ isLoading: true });

    const {
      patient: { id: patientId },
    } = useBoundDetailPatientStore.getState();
    const { start = '', end = '' } = params;

    try {
      const { data } = await Api.answers.fetchAnswersList({ patientId, start, end });

      set({ isLoading: false, data });
    } catch (error) {
      set({ isLoading: false, error: JSON.stringify(error) });
    }
  },
  onChangePeriod: (start: string, end: string) => {
    set({ range: [start, end] });
  },
});

export const useBoundAnswersStore = create<TSliceAnswers>()(
  devtools(
    immer((...a) => ({
      ...createAnswersSlice(...a),
    }))
  )
);

export * from './interfaces';
