import React, { useState } from 'react';
import { Button, Drawer, Form, Input, Space, message } from 'antd';
import runes from 'runes2';

import { useBoundPatientsStore } from '@store/slices';
import { ICreatePatient } from '@services/Patients/interfaces';

import { IFields, IProps } from './interfaces';
import MaskedInput from '@components/MaskedInput';

const AddPatient = ({ onClose, isOpen }: IProps) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { createPatient } = useBoundPatientsStore((state) => state);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (values: ICreatePatient) => {
    setLoading(true);

    createPatient(values)
      .then(async (a) => {
        onClose?.();
        form.resetFields();
      })
      .catch((err: Error | Record<string, string>) => {
        if (typeof err === 'object' && err !== null) {
          const fields = Object.keys(err).reduce<IFields[]>((acc, key) => {
            // @ts-ignore
            const val = err[key];
            acc.push({ name: key, errors: [val] });

            return acc;
          }, []);

          form.setFields(fields);
        }

        setLoading(false);

        messageApi.open({
          type: 'error',
          content: 'This is an error message',
        });
      });
  };

  return (
    <>
      {contextHolder}
      <Drawer
        placement="right"
        onClose={onClose}
        title="Добавить пациента"
        open={isOpen}
        width={480}
        extra={
          <Space>
            <Button onClick={onClose} loading={isLoading}>
              Отмена
            </Button>
            <Button onClick={() => form.submit()} type="primary" loading={isLoading}>
              Добавить
            </Button>
          </Space>
        }
      >
        <Form
          name="basic"
          initialValues={{ email: '', password: '', firstName: '', lastName: '' }}
          onFinish={onSubmit}
          autoComplete="off"
          form={form}
          layout="vertical"
        >
          <Form.Item
            label="Имя"
            name="firstName"
            required={false}
            rules={[
              {
                required: true,
                message: 'Please input your Имя!',
                min: 2,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Фамилия"
            name="lastName"
            required={false}
            rules={[
              {
                required: true,
                message: 'Please input your lastName!',
                min: 2,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Отчество" name="middleName">
            <Input />
          </Form.Item>

          <Form.Item
            label="E-mail"
            name="email"
            required={false}
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Telegram" name="telegram" required={false}>
            <Input />
          </Form.Item>

          <Form.Item label="Телефон" name="phone">
            <MaskedInput mask={'+0 (000) 000-00-00'} />
          </Form.Item>

          <Form.Item label="Заметки" name="note" required={false}>
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              maxLength={100}
              count={{ show: true, strategy: (txt) => runes(txt).length }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AddPatient;
