import { IAnswer } from '@services/Answers/interfaces';
import { ETypeInput } from '@src/interfaces/common';
import { ICountByProgressValue, IExtendedAnswerItem } from './interfaces';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import ru from 'dayjs/locale/ru';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(weekOfYear);
dayjs.extend(isBetween);

const getTotalFields = (prev: IAnswer['fields'], fields: IAnswer['fields']) => {
  const keys = Object.keys(fields);

  return keys.reduce<IAnswer['fields']>((acc, key: string) => {
    acc[key] = Number(acc[key] || 0) + Number(fields[key] || 0);

    return acc;
  }, prev);
};

export const compactByMonth = (data: IAnswer[]): IExtendedAnswerItem[] => {
  const total: Record<number, IAnswer['fields']> = {};

  data.forEach(({ day, fields }) => {
    const month = dayjs(day, 'DD.MM.YY').month();

    if (total[month]) {
      total[month] = {
        ...getTotalFields(total[month], fields),
        // @ts-ignore
        count: (total[month].count || 0) + 1,
      };
    } else {
      total[month] = { ...fields, count: 1 };
    }
  });

  return Object.keys(total).reduce<IExtendedAnswerItem[]>((acc, key) => {
    const current = dayjs().locale(ru).month(Number(key)).format('MMMM');

    acc.push({
      day: current,
      id: uuidv4(),
      // @ts-ignore
      fields: total[key],
      createdAt: current,
      week: Number(key),
      // @ts-ignore
      count: total[key].count,
      isToday: false,
    });

    return acc;
  }, []);
};

export const compactByWeek = (data: IAnswer[]): IExtendedAnswerItem[] => {
  const total: Record<number, IAnswer['fields']> = {};

  data.forEach(({ fields, day }) => {
    const week = dayjs(day, 'DD.MM.YYYY').week();

    if (total[week]) {
      // @ts-ignore
      total[week] = { ...getTotalFields(total[week], fields), count: (total[week].count || 0) + 1 };
    } else {
      total[week] = { ...fields, count: 1 };
    }
  });

  return Object.keys(total).reduce<IExtendedAnswerItem[]>((acc, key) => {
    const current = dayjs().startOf('year').week(Number(key));
    const start = current.startOf('week').format('DD.MM');
    const end = current.endOf('week').format('DD.MM');
    const day = `${start} ~ ${end}`;

    acc.push({
      day,
      id: uuidv4(),
      // @ts-ignore
      fields: total[key],
      createdAt: day,
      week: Number(key),
      // @ts-ignore
      count: total[key].count,
      isToday: false,
    });

    return acc;
  }, []);
};

export const getCountByProgressValue = ({
  type,
  max,
  value,
}: ICountByProgressValue): 'danger' | 'success' | 'warning' | 'default' => {
  if (type === ETypeInput.Slider) {
    const mid = Math.ceil(max / 2);

    if (mid === value) {
      return 'warning';
    }

    return value > mid ? 'danger' : 'success';
  }

  if (type === ETypeInput.TextArea) {
    return 'default';
  }

  return value ? 'danger' : 'success';
};
