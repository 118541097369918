import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import dayjs from 'dayjs';

import Api from '@services/index';
import { StatusEnum } from '@src/interfaces/common';

import { useBoundPollsStore } from '../polls';
import { TSliceDetailPatient } from './interfaces';
import { IAddTrainer, IUpdateNotePaylod } from '@services/Patients/interfaces';

const createDetailsPatientSlice: StateCreator<
  TSliceDetailPatient,
  [['zustand/immer', TSliceDetailPatient], ['zustand/devtools', never]],
  [],
  TSliceDetailPatient
> = (set, get) => ({
  patient: {
    id: 0,
    name: '',
    isPause: false,
    isDelete: false,
    pauseEndDate: '',
    note: '',
    telegram: '',
    phone: '',
    firstName: '',
    lastName: '',
    middleName: '',
    trainers: [],
    user: {
      id: 0,
      email: '',
      provider: '',
      status: {
        id: StatusEnum.default,
      },
    },
    createdAt: new Date(),
    updatedAt: new Date(),
    status: {
      id: StatusEnum.draft,
    },
  },
  isLoading: false,
  error: '',
  statistics: [],
  activities: [],
  getStatistics: async (id: string) => {
    try {
      const { data: statistics } = await Api.patients.fetchStatistics(id);

      set({ statistics });
    } catch (error) {
      set({ error: JSON.stringify(error) });
    }
  },
  getPatient: async (id: string) => {
    set({ isLoading: true });

    try {
      const { data: patient } = await Api.patients.fetchDetailPatient(id);

      set({ patient, isLoading: false });
    } catch (error) {
      set({ isLoading: false, error: JSON.stringify(error) });
      throw new Error('Пациент не найден');
    }
  },
  sendPollsByPatient: async () => {
    try {
      const { patient } = get();
      const params = {
        patientId: patient.id,
        polls: useBoundPollsStore.getState().disabled,
      };
      // @ts-ignore
      const { data: hash } = await Api.patients.sendPollsByPatient(params);

      set({
        patient: {
          ...patient,
          status: {
            id: StatusEnum.invated,
          },
          user: {
            ...patient.user,
            hash,
          },
        },
      });
    } catch (error) {
      console.log('====err', error);
    }
  },

  removePatient: async (id: string) => {
    try {
      const response = await Api.patients.removePatient(id);

      if (!response) {
        throw new Error('Ошибка');
      }

      const { patient } = get();

      set({
        patient: {
          ...patient,
          isDelete: true,
          status: {
            id: StatusEnum.inactive,
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  },

  updateNote: async (params: IUpdateNotePaylod) => {
    try {
      const response = await Api.patients.updateNote(params);

      if (!response) {
        throw new Error('Ошибка');
      }

      const { patient } = get();

      set({
        patient: {
          ...patient,
          note: params.note,
        },
      });
    } catch (err) {
      console.error(err);
    }
  },

  pausePatient: async (patientId: string) => {
    try {
      const response = await Api.patients.pausePatient(patientId);
    } catch (error) {
      set({ error: JSON.stringify(error) });
    }
  },
  addTrainerByPatient: async (params: IAddTrainer) => {
    try {
      const response = await Api.patients.addTrainerByPatient(params);

      if (!response) {
        throw new Error('Ошибка');
      }

      set((state) => {
        state.patient.trainers = params.ids;
      });
    } catch (error) {
      set({ error: JSON.stringify(error) });
    }
  },
});

export const useBoundDetailPatientStore = create<TSliceDetailPatient>()(
  devtools(
    immer((...a) => ({
      ...createDetailsPatientSlice(...a),
    }))
  )
);

export * from './interfaces';
