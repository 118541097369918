import React, { useEffect, useState, PropsWithChildren, ReactElement } from 'react';
import { Spin } from 'antd';

import { useBoundUserStore } from '@store/slices/user';

const Root = ({ children }: PropsWithChildren): ReactElement => {
  const [loading, setLoading] = useState(true);
  const { getUserMe } = useBoundUserStore(({ getUserMe }) => ({
    getUserMe,
  }));

  useEffect(() => {
    getUserMe()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '400px',
        }}
      >
        <Spin />
      </div>
    );
  }

  return <div>{children}</div>;
};

export default Root;
