import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Layout, Dropdown, Flex, Avatar, Space, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd/lib';

import { useBoundUserStore } from '@store/slices/user';
import { ReactComponent as Logo } from '@assets/logo.svg';

const { Header } = Layout;

const headerStyle: React.CSSProperties = {
  height: 'inherit',
  paddingInline: 14,
  paddingTop: 34,
  paddingBottom: 34,
  lineHeight: 1,
  backgroundColor: 'transparent',
};

const Top = () => {
  const { logOut } = useBoundUserStore(({ logOut }) => ({ logOut }));

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Link to="/main/profile">Профиль</Link>,
      icon: <UserOutlined />,
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      onClick: logOut,
      label: <div>Выйти</div>,
      danger: true,
    },
  ];

  return (
    <Header style={headerStyle}>
      <div style={{ maxWidth: '1240px', margin: '0 auto' }}>
        <Flex justify="space-between" align="center">
          <Space>
            <div style={{ marginRight: '34px' }}>
              <Logo width={160} height={60} />
            </div>
            <NavLink to="/main/patients">
              <Typography.Link>Пациенты</Typography.Link>
            </NavLink>
            <NavLink to="/main/trainers">
              <Typography.Link>Тренера</Typography.Link>
            </NavLink>
          </Space>

          <Dropdown menu={{ items }} trigger={['click']}>
            <Avatar icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
          </Dropdown>
        </Flex>
      </div>
    </Header>
  );
};

export default Top;
