import React, { useState } from 'react';
import { Button, Drawer, Form, Space, message } from 'antd';

import { IFields, IProps } from './interfaces';
import { useBoundTrainersStore } from '@store/slices/trainers';
import { IPayloadTrainer } from '@services/Trainers/interfaces';
import TrainerForm from '../../Form';

const AddTrainer = ({ onClose, isOpen }: IProps) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);
  const { addTrainer } = useBoundTrainersStore(({ addTrainer }) => ({
    addTrainer,
  }));

  const onSubmit = (values: IPayloadTrainer) => {
    setLoading(true);

    addTrainer(values)
      .then(async () => {
        onClose?.();
        setLoading(false);
        form.resetFields();
      })
      .catch((err: Error | Record<string, string>) => {
        if (typeof err === 'object' && err !== null) {
          const fields = Object.keys(err).reduce<IFields[]>((acc, key) => {
            // @ts-ignore
            const val = err[key];
            acc.push({ name: key, errors: [val] });

            return acc;
          }, []);

          form.setFields(fields);
        }

        setLoading(false);

        messageApi.open({
          type: 'error',
          content: 'This is an error message',
        });
      });
  };

  return (
    <>
      {contextHolder}
      <Drawer
        placement="right"
        onClose={onClose}
        title="Добавить пациента"
        open={isOpen}
        width={480}
        extra={
          <Space>
            <Button onClick={onClose} loading={isLoading}>
              Отмена
            </Button>
            <Button onClick={() => form.submit()} type="primary" loading={isLoading}>
              Добавить
            </Button>
          </Space>
        }
      >
        <TrainerForm form={form} onSubmit={onSubmit} />
      </Drawer>
    </>
  );
};

export default AddTrainer;
