import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import dayjs from 'dayjs';

import { useBoundDetailPatientStore } from '@store/slices/detailsPatient';
import { useBoundPollsStore } from '@store/slices/polls';
import { useBoundAnswersStore } from '@store/slices/answers';

import Info from './Info';
import Main from './Main';

import styles from './styles.module.less';

const DetailsPatient = (): ReactElement => {
  const { patientId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const getPatient = useBoundDetailPatientStore(({ getPatient }) => getPatient);
  const getPollsList = useBoundPollsStore(({ getPollsList }) => getPollsList);
  const getAnswers = useBoundAnswersStore(({ getAnswers }) => getAnswers);

  useEffect(() => {
    if (patientId) {
      Promise.all([getPatient(patientId), getPollsList(patientId)])
        .then(() => {
          setLoading(false);
          getAnswers({ start: dayjs().startOf('year').toISOString(), end: dayjs().toISOString() });
        })
        .catch((error) => {
          setError('true');
          setLoading(false);
          console.error(error);
        });
    }
  }, [patientId]);

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <div className={styles.detail}>
      <Info />
      <Main />
    </div>
  );
};

export default DetailsPatient;
