import React, { useMemo } from 'react';
import { Flex, Tooltip, theme, Typography } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { useBoundAnswersStore } from '@store/slices/answers';

import HeatMap from './HeatMap';
import { EStatusAnswer } from './interfaces';
import styles from './styles.module.less';

const { Text, Title } = Typography;

dayjs.extend(isBetween);

const Activity = () => {
  const { token } = theme.useToken();
  const { data, isLoading } = useBoundAnswersStore(({ data, isLoading }) => ({ data, isLoading }));

  const activeDates = useMemo(
    () =>
      data.reduce<Record<string, EStatusAnswer>>((acc, { day, isToday }) => {
        acc[day] = isToday ? EStatusAnswer.Success : EStatusAnswer.Warning;

        return acc;
      }, {}),
    [data]
  );

  return (
    <div>
      <Title level={3}>Активность</Title>

      <div className={styles.activity}>
        <HeatMap activeDates={activeDates} />

        <Flex justify="space-between">
          <Text type="secondary">
            {Object.keys(activeDates).length} активностей за последние год
          </Text>

          <Flex gap={4}>
            <Tooltip placement="top" title="Неактивные дни">
              <div
                className={styles.cell}
                style={{ backgroundColor: '#c6c6c6', cursor: 'pointer' }}
              />
            </Tooltip>

            <Tooltip placement="top" title="Один ответ">
              <div
                className={styles.cell}
                style={{ backgroundColor: token.colorSuccess, cursor: 'pointer' }}
              />
            </Tooltip>

            <Tooltip placement="top" title="Больше одного ответа за день">
              <div
                className={styles.cell}
                style={{ backgroundColor: token.colorWarning, cursor: 'pointer' }}
              />
            </Tooltip>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

export default Activity;
