import React, { ReactElement, useMemo, useState } from 'react';
import { Space, DatePicker, Typography, Radio, Pagination, Flex } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { useBoundAnswersStore } from '@store/slices/answers';

import Activity from '../../Activity';
import { compactByWeek, compactByMonth } from './utils';
import { IExtendedAnswerItem } from './interfaces';
import StatisticsTable from './Table';
import { PaginationProps } from 'antd/lib';
import styles from './styles.module.less';

const { RangePicker } = DatePicker;

dayjs.extend(isBetween);

const Statistics = (): ReactElement => {
  const [page, setPage] = useState<number>(1);
  const [selectedPeriod, setSelectedPeriod] = useState<'day' | 'week' | 'month'>('day');
  const [range, setRange] = useState<[Dayjs | null | undefined, Dayjs | null | undefined]>([
    null,
    null,
  ]);
  const { data: dataTable, isLoading } = useBoundAnswersStore(({ data, isLoading }) => ({
    data,
    isLoading,
  }));

  const filtredDataTable = useMemo(() => {
    const [start, end] = range;

    if (start && end) {
      return dataTable.filter(({ day }) => dayjs(day, 'DD.MM.YYYY').isBetween(start, end, 'date'));
    }

    return dataTable;
  }, [range, dataTable]);

  const data = useMemo(() => {
    const current = page - 1 || 0;
    const startPerPage = 20 * current;
    const endPerPage = 20 * (current + 1);

    const newData = filtredDataTable.slice(startPerPage, endPerPage);
    const prerapedData: Record<'day' | 'week' | 'month', IExtendedAnswerItem[]> = {
      day: newData,
      week: compactByWeek(newData),
      month: compactByMonth(newData),
    };

    return prerapedData[selectedPeriod];
  }, [selectedPeriod, filtredDataTable, page]);

  const options = [
    { value: 'day', label: 'Дни' },
    { value: 'week', label: 'Недели' },
    { value: 'month', label: 'Месяца' },
  ];

  const onChange: PaginationProps['onChange'] = (page) => {
    setPage(page);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Activity />

      <Typography.Title level={2}>Блоки карточек</Typography.Title>

      <Flex justify="space-between" className={styles.filter}>
        <Space>
          <RangePicker
            value={range}
            onChange={(values) => {
              setRange(values ?? [null, null]);
            }}
          />
          <Space>
            <Radio.Group value={selectedPeriod} onChange={(e) => setSelectedPeriod(e.target.value)}>
              {options.map(({ label, value }) => (
                <Radio.Button value={value} key={value}>
                  {label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Space>
        </Space>

        <Pagination
          current={page}
          onChange={onChange}
          total={filtredDataTable.length}
          defaultPageSize={20}
        />
      </Flex>

      {!isLoading && <StatisticsTable dataTable={data} selectedPeriod={selectedPeriod} />}
    </div>
  );
};

export default Statistics;
