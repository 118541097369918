export const swap = <T = string[]>(direction: 'up' | 'down', index: number, array: T[]) => {
  const temp = array[index];

  if (direction === 'up' && index !== 0) {
    array[index] = array[index - 1];
    array[index - 1] = temp;
  }

  if (direction === 'down' && index !== array.length - 1) {
    array[index] = array[index + 1];
    array[index + 1] = temp;
  }

  return array;
};

export const stringToColor = (string: string) => {
  const str = (string || '').toLocaleLowerCase();
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string) => ({
  sx: {
    bgcolor: stringToColor(name),
  },
  children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
});

export const isIsoDate = (str: string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
    return false;
  }

  const d = new Date(str);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
};
