import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Table, Skeleton, Input } from 'antd';
import { SearchOutlined, UserAddOutlined } from '@ant-design/icons';

import { IPatient } from '@services/Patients/interfaces';
import EditPatient from '@components/EditPatient';

import AddPatient from './AddPatient';
import { getColumns } from './utils';
import { IOpenEdit, IProps } from './interfaces';
import styles from './styles.module.less';

const DataTable = ({ data, isLoading }: IProps) => {
  const navigate = useNavigate();
  const [openEdit, setOpenEdit] = useState<IOpenEdit>({ open: false, patient: null });
  const [search, setSearch] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };

  const handleHideDrawer = () => {
    setShowDrawer(false);
  };

  const handleOpenEditModal = (patient: IPatient) => {
    setOpenEdit({ open: true, patient });
  };

  const filtredData = useMemo(
    () => data.filter(({ name }) => name?.toLocaleLowerCase().includes(search)),
    [data, search]
  );
  const columns = getColumns({ onOpenEdit: handleOpenEditModal });

  return (
    <div>
      {openEdit.open && (
        <EditPatient
          isOpen={openEdit.open}
          onClose={() => setOpenEdit({ open: false, patient: null })}
          patient={openEdit.patient!}
        />
      )}
      <AddPatient onClose={handleHideDrawer} isOpen={showDrawer} />
      <Skeleton loading={isLoading}>
        <Flex justify="space-between" align="center" style={{ marginBottom: '24px' }}>
          <div style={{ maxWidth: '320px', width: '100%' }}>
            <Input
              addonBefore={<SearchOutlined />}
              allowClear
              placeholder="Иван"
              onChange={(event) => {
                setSearch(event.target.value.toLocaleLowerCase());
              }}
            />
          </div>

          <Button onClick={handleShowDrawer} type="primary" icon={<UserAddOutlined />}>
            Добавить
          </Button>
        </Flex>
      </Skeleton>

      <Table
        columns={columns}
        dataSource={filtredData}
        loading={isLoading}
        pagination={false}
        rowKey="id"
        rowClassName={(record) => (record.isDelete ? styles['disabled-row'] : '')}
        onRow={(record) => {
          return {
            style: {
              cursor: 'pointer',
            },
            onClick: () => {
              navigate(`/main/patients/${record.id}`);
            },
          };
        }}
      />
    </div>
  );
};

export default DataTable;
