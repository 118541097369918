import React, { useState } from 'react';
import { Tabs, TabsProps } from 'antd';

import Statistics from './Statistics';
import Polls from './Polls';
import Trainers from './Trainers';

const Main = () => {
  const [active, setActive] = useState<string>('1');

  const onChange = (tabId: string) => {
    setActive(tabId);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Статистика',
      children: <Statistics />,
    },
    {
      key: '2',
      label: 'Опросы',
      children: <Polls />,
    },
    {
      key: '3',
      label: 'Тренеры',
      children: <Trainers />,
    },
  ];

  return (
    <div style={{ minHeight: '400px' }}>
      <Tabs activeKey={active} items={items} onChange={onChange} />
    </div>
  );
};

export default Main;
