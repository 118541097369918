import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import Api from '@services/index';

import { TSlicePolls } from './interfaces';

const createPollsSlice: StateCreator<
  TSlicePolls,
  [['zustand/immer', TSlicePolls], ['zustand/devtools', never]],
  [],
  TSlicePolls
> = (set, get) => ({
  items: [],
  disabled: [],
  uniqueKeys: [],
  isLoading: false,
  error: '',
  getPollsList: async (patientId: string) => {
    set({ isLoading: true });

    try {
      const { data } = await Api.polls.fetchPollsList(patientId);

      set({ isLoading: false, ...data });
    } catch (error) {
      set({ isLoading: false, error: JSON.stringify(error) });
    }
  },
  updatePolls: async (patientId: string, disabledList: string[]) => {
    try {
      await Api.polls.updatePolls({ disabled: disabledList, patientId });
    } catch (error) {
      console.log('==error');
    }
  },
});

export const useBoundPollsStore = create<TSlicePolls>()(
  devtools(
    immer((...a) => ({
      ...createPollsSlice(...a),
    }))
  )
);

export * from './interfaces';
