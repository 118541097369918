import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import Api from '@services/index';

import { TSlicePatients } from './interfaces';
import { ELoadingStatus } from '@src/interfaces/common';
import { IUpdatePatient } from '@services/Patients/interfaces';
import { useBoundDetailPatientStore } from '../detailsPatient';

const createPatientsSlice: StateCreator<
  TSlicePatients,
  [['zustand/immer', TSlicePatients], ['zustand/devtools', never]],
  [],
  TSlicePatients
> = (set, get) => ({
  data: [],
  loadingStatus: ELoadingStatus.Pending,
  error: '',
  fetchPatients: async () => {
    const { data } = get();
    const isDataFulled = Boolean(data.length);

    if (!isDataFulled) {
      set({ loadingStatus: ELoadingStatus.Pending });
    }

    try {
      const { data } = await Api.patients.fetchPatients();

      set({ loadingStatus: ELoadingStatus.Ok, data });
    } catch (error) {
      set({ loadingStatus: ELoadingStatus.Fail, error: JSON.stringify(error) });
    }
  },
  createPatient: async (props) => {
    try {
      const { data: newPatient } = await Api.patients.createPatient(props);

      set((state) => ({ data: [...state.data, newPatient] }));
    } catch (error) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw error?.response?.data?.errors;
    }
  },
  updatePatient: async (params: IUpdatePatient) => {
    try {
      const { data: newPatient } = await Api.patients.updatePatient(params);

      return set((state) => {
        const idx = state.data.findIndex(({ id }) => id === params.id);

        state.data[idx] = newPatient;

        useBoundDetailPatientStore.setState((state) => {
          if (params.note) {
            state.patient.note = params.note;
          }

          return state;
        });
      });
    } catch (error) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw error?.response?.data?.errors;
    }
  },
});

export const useBoundPatientsStore = create<TSlicePatients>()(
  devtools(
    immer((...a) => ({
      ...createPatientsSlice(...a),
    }))
  )
);

export * from './interfaces';
