import axiosInstance from '@services/axios';

import { IPayloadUpdateDoctor, TDoctorInfoResponse } from './interfaces';

class DoctorsService {
  async fetchInfoDoctor(): Promise<TDoctorInfoResponse> {
    const response = await axiosInstance.get<TDoctorInfoResponse>('/doctors/info');

    return response.data;
  }

  async updateInfoDoctor(params: IPayloadUpdateDoctor): Promise<TDoctorInfoResponse> {
    const { doctorId, ...rest } = params;
    const response = await axiosInstance.put<TDoctorInfoResponse>(`/doctors/info/${doctorId}`, {
      ...rest,
    });

    return response.data;
  }
}

export const Doctors = new DoctorsService();
