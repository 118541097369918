import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Flex, List, Switch, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { useBoundPollsStore } from '@store/slices/polls';
import { IPoll } from '@services/Polls/interfaces';

const Polls = () => {
  const { items, updatePolls, disabled } = useBoundPollsStore(
    ({ items, updatePolls, disabled }) => ({
      items,
      updatePolls,
      disabled,
    })
  );
  const { patientId } = useParams();

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [disabledList, setDisabledList] = useState<string[]>(disabled);

  const onChange = (key: string) => (checked: boolean) => {
    const hasItem = disabledList.includes(key);

    if (checked && hasItem) {
      setDisabledList((prevState) => prevState.filter((id) => id !== key));
      return;
    }

    setDisabledList((prevState) => [...prevState, key]);
  };

  const onClick = (): void => {
    setLoading(() => true);

    if (patientId) {
      updatePolls(patientId, disabledList)
        .then(() => {
          setLoading(() => false);
        })
        .catch(() => {
          setLoading(() => false);
        })
        .finally(() => {
          setEdit(false);
        });
    }
  };

  const renderPolls = ({ title, key, children }: IPoll) => (
    <Card title={title} key={key} style={{ marginBottom: '24px' }}>
      <List
        dataSource={children}
        renderItem={(child) => (
          <List.Item
            key={child.key}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px 14px',
            }}
          >
            <div>
              <h6>{child.title}</h6>
              <p>{child.description}</p>
            </div>
            <Switch
              onChange={onChange(child.key)}
              disabled={!edit}
              value={!disabledList.includes(child.key)}
            />
          </List.Item>
        )}
      />
    </Card>
  );

  const onToggle = () => {
    setEdit(!edit);
  };

  const onCancel = () => {
    setDisabledList(disabled);
    setEdit(false);
  };

  return (
    <div>
      <Flex justify="space-between" style={{ marginBottom: '24px' }}>
        <div>
          <Typography.Title level={3}>Опросы</Typography.Title>
          <Typography.Text>Назначение опроса на пациента</Typography.Text>
        </div>

        <Flex gap={12}>
          {!edit ? (
            <Button onClick={onToggle} icon={<EditOutlined />} htmlType="button">
              Редактировать
            </Button>
          ) : (
            <>
              <Button onClick={onCancel} htmlType="button" danger>
                Отмена
              </Button>
              <Button onClick={onClick} htmlType="button" type="primary">
                Сохранить
              </Button>
            </>
          )}
        </Flex>
      </Flex>

      {items.map(renderPolls)}
    </div>
  );
};

export default Polls;
