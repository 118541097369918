/* eslint-disable @typescript-eslint/consistent-type-assertions */
import axios, { AxiosError, AxiosInstance, AxiosRequestHeaders, AxiosResponse } from 'axios';

import { router } from '@src/views/routes';
import Api from '@services/index';
import { getStorageToken } from '@utils/storageUser';

import { IBaseResponse } from './interfaces';

export const instanceOptions = {
  baseURL: process.env.REACT_APP_SERVICE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=utf-8',
  },
  withCredentials: true,
};

const axiosInstance: AxiosInstance = axios.create(instanceOptions);

axiosInstance.interceptors.request.use(
  async (config) => {
    let { token, tokenExpires } = getStorageToken();

    if (!token) {
      throw new AxiosError('TokenNotFound', 'TokenNotFound', config);
    }

    if (Date.now() > tokenExpires) {
      token = await Api.user.refreshToken();
    }

    const customHeader = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    } as AxiosRequestHeaders;

    return {
      ...config,
      headers: customHeader,
    };
  },
  async (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  async (response: AxiosResponse<IBaseResponse<Record<string, unknown>>>) => response,
  async (error: AxiosError<IBaseResponse<Record<string, unknown>>, { _retry?: boolean }>) => {
    if (error.message === 'TokenNotFound') {
      router.navigate('/auth/login', { replace: true });

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
