import React, { useState } from 'react';
import { Button, Flex, Form, Input, Typography, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { IFields } from './interfaces';
import { useBoundUserStore } from '@store/slices/user';
import { IRegisterRequest } from '@services/User/interfaces';

const { Title } = Typography;

const SignUp = () => {
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { register } = useBoundUserStore(({ register }) => ({ register }));
  const [form] = Form.useForm<{
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }>();

  const onSubmit = (values: IRegisterRequest) => {
    setLoading(true);

    register(values)
      .then(() => {
        setLoading(false);
        messageApi.open({
          type: 'success',
          content: 'Регистрация прошла успешно',
        });
      })
      .catch((err: Error | Record<keyof IRegisterRequest, string>) => {
        if (typeof err === 'object' && err !== null) {
          const fields = Object.keys(err).reduce<IFields[]>((acc, key) => {
            // @ts-ignore
            const val = err[key];
            acc.push({ name: key, errors: [val] });

            return acc;
          }, []);

          form.setFields(fields);
        }

        setLoading(false);

        messageApi.open({
          type: 'error',
          content: 'This is an error message',
        });
      });
  };

  return (
    <Flex gap="middle" vertical>
      {contextHolder}
      <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>
        Зарегистрироваться
      </Title>

      <Form
        name="basic"
        initialValues={{ email: '', password: '', firstName: '', lastName: '' }}
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
        layout="vertical"
      >
        <Form.Item<IRegisterRequest>
          label="E-mail"
          name="email"
          required={false}
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<IRegisterRequest>
          label="Пароль"
          name="password"
          required={false}
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item<IRegisterRequest>
          label="Имя"
          name="firstName"
          required={false}
          rules={[{ required: true, message: 'Please input your name!', min: 2 }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<IRegisterRequest>
          label="Фамилия"
          name="lastName"
          required={false}
          rules={[{ required: true, message: 'Please input your lastname!', min: 2 }]}
        >
          <Input />
        </Form.Item>

        <div style={{ margin: '20px 0' }}>
          <Link to="/auth/login">Авторизоваться </Link>
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ width: '100%' }}
            size="large"
          >
            Зарегистрироваться
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default SignUp;
